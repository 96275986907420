<template>
    <div>
        <vue-element-loading :active="appLoading" :is-full-screen="true" background-color="#FFFFFF" color="#005f32"
            spinner="bar-fade-scale" />
        <v-snackbar v-model="showSnackBar" color="#005f32" right>
            <v-layout wrap justify-center>
                <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
                <v-flex text-right>
                    <v-btn small :ripple="false" text @click="showSnackBar = false">
                        <v-icon style="color: white">mdi-close</v-icon>
                    </v-btn>
                </v-flex>
            </v-layout>
        </v-snackbar>
        <v-layout wrap justify-center>
            <v-flex xs12>
                <v-layout wrap justify-center>
                    <v-flex xs12>
                        <v-card-title class="elevation-0">
                            <span class="itemHeading">WILD BYTES</span>
                            <v-spacer></v-spacer>
                            <!-- <v-flex text-end align-self-center>
                              <v-btn color="#005f32" rounded outlined class="mb-4" @click="addTargets = true">
                                <span style="font-family: kumbhRegular;">Add Targets</span>
                              </v-btn>
                            </v-flex> -->
                            <v-flex text-end align-self-center>
                                <v-btn color="#005f32" rounded outlined class="mb-4" @click="addBlog = true">
                                    <span style="font-family: kumbhRegular;">Add WILD BYTES</span>
                                </v-btn>
                            </v-flex>
                        </v-card-title>
                        <!-- Table section -->

                        <v-dialog v-model="addBlog" max-width="80%">
                            <v-card>
                                <v-card-title>
                                    <span class="itemHeading">ADD WILD BYTES</span>
                                </v-card-title>
                                <v-card-text>
                                    <v-form>
                                        <v-text-field dense outlined v-model="title" label="Title"
                                            required></v-text-field>
                                        <v-textarea v-model="subDescription" dense outlined label="Sub description"
                                            required></v-textarea>
                                        <!-- <v-textarea v-model="content" dense outlined label="Content"
                                            required></v-textarea> -->
                                        <vue-editor class="pt-2 text-des" style="font-size:14px"
                                            v-model="content"></vue-editor>

                                        <v-flex pt-6>
                                            <!-- Image Upload -->
                                            <!-- <v-file-input 
                                                  label="Upload Image" 
                                                  dense 
                                                  outlined 
                                                  accept="image/*" 
                                                  @change="onFileChange"
                                                  show-size="false"
                                                  hide-details="auto"
                                                >
                                                </v-file-input> -->
                                            <v-file-input label="Upload Cover Image" dense outlined accept="image/*"
                                                @change="onFileChange" :show-size="false" hide-details="auto">
                                            </v-file-input>

                                            <br>
                                            <!-- Show Cropper only after image is selected -->
                                            <cropper v-if="imageSelected" ref="cropper" :src="imageURL"
                                                :stencil-props="{ aspectRatio: 16 / 9 }" :resize-image="true"
                                                :auto-zoom="true" :background="true" :guides="true" />
                                        </v-flex>


                                    </v-form>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="red darken-1" text @click="addBlog = false">Cancel</v-btn>
                                    <v-btn color="blue darken-1" text @click="addBlogs">Submit</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>


                        <v-layout wrap justify-center px-4>
                            <v-flex xs12>
                                <v-card>
                                    <v-card-text>
                                        <!-- Church Detail Card Section -->
                                        <v-layout wrap justify-start v-if="bloglist?.length > 0"  
                                            >
                                            <v-flex xs12 sm6 md4 lg3 v-for="(item, index) in bloglist" :key="index"  @click="redirectToViewPage(item)"
                                                class="d-flex mb-3">
                                                <v-layout wrap justify-center>
                                                    <v-flex xs12 pa-2>
                                                        <v-card class="video-card" elevation="1" height="100%">
                                                            <v-flex xs12>
                                                                <v-img :src="mediaURL + item.images[0]"
                                                                    max-height="300px" max-width="100%"></v-img>
                                                            </v-flex>



                                                            <v-flex text-start pb-0 style="flex-grow: 1">
                                                                <v-layout wrap justify-start>
                                                                    <v-flex xs10 py-2 pt-4 pl-3>
                                                                        <span class="video-title">
                                                                            {{ item.title }}
                                                                        </span>
                                                                    </v-flex>
                                                                    <v-flex xs2 class="d-flex align-center justify-end">
                                                                        <v-menu offset-y>
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn v-bind="attrs" v-on="on" icon>
                                                                                    <v-icon
                                                                                        color="black">mdi-dots-vertical</v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <v-list>
                                                                                <v-list-item @click="editItem(item)">
                                                                                    <v-icon small
                                                                                        color="primary">mdi-pencil</v-icon>
                                                                                    <v-list-item-content
                                                                                        class="pl-3 addChurch">Edit</v-list-item-content>
                                                                                </v-list-item>

                                                                                <v-list-item
                                                                                    @click.stop="openDeleteDialog(item)">
                                                                                    <v-icon color="red"
                                                                                        small>mdi-delete</v-icon>
                                                                                    <v-list-item-content
                                                                                        class="pl-3 addChurch">Delete</v-list-item-content>
                                                                                </v-list-item>
                                                                            </v-list>
                                                                        </v-menu>
                                                                    </v-flex>
                                                                </v-layout>
                                                                <v-flex pt-1>
                                                                    <v-divider></v-divider>
                                                                </v-flex>
                                                                <v-flex px-3 class="live-des mt-1">
                                                                    <span
                                                                        @click.stop="item.shortDiscription && item.shortDiscription.length > 40 && showFullDescription(item.shortDiscription)">
                                                                        {{ item.shortDiscription ?
                                                                            item.shortDiscription.slice(0, 40) : ''
                                                                        }}</span>
                                                                    <span
                                                                        v-if="item.shortDiscription && item.shortDiscription.length > 40">....</span>

                                                                </v-flex>

                                                                <!-- <v-flex px-3 class="live-des mt-1">
                                                                    <span v-html="item.content"></span>
                                                                  </v-flex> -->

                                                            </v-flex>
                                                        </v-card>
                                                    </v-flex>
                                                </v-layout>
                                            </v-flex>
                                        </v-layout>

                                        <v-layout v-else>
                                            <v-flex text-center style="font-size: large;font-family: kumbhRegular"
                                                class="k">
                                                No Data Available
                                            </v-flex>
                                        </v-layout>
                                    </v-card-text>
                                </v-card>

                            </v-flex>
                        </v-layout>

                    </v-flex>
                </v-layout>




            </v-flex>
        </v-layout>

        <!-- description dialog-box -->


        <v-dialog persistent v-model="dialogdes" max-width="800px">
            <v-card>
                <v-layout wrap>
                    <v-flex xs12 pa-4 text-left align-self-center style="background: #005f32 !important">
                        <v-layout wrap>
                            <v-flex xs6 align-self-center text-left>
                                <span class="kumbhBold" style="color: #ffffff; font-size: 20px">
                                    Full Description
                                </span>
                            </v-flex>
                            <v-flex xs6 align-self-center text-right>
                                <v-btn icon color="white" text @click="dialogdes = false">
                                    <v-icon color="#ffffff">mdi-close</v-icon>
                                </v-btn>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                    <v-flex xs12 pa-4></v-flex>
                    <v-flex xs12>
                        <v-layout wrap px-5 pb-8>
                            <v-flex xs12 align-self-center text-left>
                                <span class="kumbhRegular" style="color: #000; font-size: 18px"> {{ fullDescription
                                    }}</span>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                </v-layout>
            </v-card>
        </v-dialog>


        <!-- description dialog-box -->

        <v-dialog v-model="dialogdes2" max-width="600px">
            <v-card>
                <v-card-title class="card-header">Full Description</v-card-title>
                <v-card-text class="text-des pt-3">
                    {{ fullDescription2 }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn style="font-family: interregular; font-size: 13px; color: white;" color="#039379"
                        @click="dialogdes2 = false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- edit dialog -->
        <v-dialog v-model="editBlogDialog" max-width="80%">
            <v-card>
                <v-card-title>
                    <span class="itemHeading">EDIT WILD BYTES</span>
                </v-card-title>
                <v-card-text>
                    <v-form ref="editForm" v-model="valid">
                        <v-text-field dense outlined v-model="editedBlog.title" label="Title" required></v-text-field>

                        <v-textarea v-model="editedBlog.shortDiscription" dense outlined label="Subdescription"
                            required></v-textarea>

                        <!-- <v-textarea v-model="editedBlog.content" dense outlined label="Content" required></v-textarea> -->
                        <vue-editor v-model="editedBlog.content" dense outlined label="Content" required></vue-editor>


                        <!-- Display Existing Image --><br>
                        <v-img v-if="editedBlog.images && editedBlog.images.length > 0 && !editImageSelected"
                            :src="mediaURL + editedBlog.images[0]" max-height="300px" max-width="100%"
                            class="mb-2"></v-img>

                        <!-- Image Upload & Cropper -->
                        <v-file-input label="Change Cover Image" dense outlined @change="onEditFileChange2"
                            accept="image/*"></v-file-input>

                        <!-- Show Cropper only after image is selected -->
                        <Cropper v-if="editImageSelected" ref="editCropper" :src="editImageURL"
                            :stencil-props="{ aspectRatio: 16 / 9 }" class="cropper" />

                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text @click="editBlogDialog = false">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="updateBlog">Update</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- delete dialog -->

        <v-dialog persistent v-model="deletedialog" max-width="600px">
            <v-card>
                <v-layout wrap>
                    <v-flex xs12 pa-4 text-left align-self-center style="background: #005f32 !important">
                        <v-layout wrap>
                            <v-flex xs6 align-self-center text-left>
                                <span class="kumbhBold" style="color: #ffffff; font-size: 20px">
                                    DELETE WILD BYTES
                                </span>
                            </v-flex>
                            <v-flex xs6 align-self-center text-right>
                                <v-btn icon color="white" text @click="deletedialog = false">
                                    <v-icon color="#ffffff">mdi-close</v-icon>
                                </v-btn>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                    <v-flex xs12 pa-4></v-flex>
                    <v-flex xs12>
                        <v-layout wrap px-5 pb-8>
                            <v-flex xs12 align-self-center text-left>
                                <span class="kumbhBold" style="color: #000; font-size: 20px">Are you sure you want to
                                    delete
                                    this
                                    byte?</span>
                            </v-flex>

                            <v-flex xs12 align-self-center text-right pt-3>
                                <v-btn @click="confirmDelete" color="#005f32">
                                    <span class="kumbhBold" style="color: white"> Delete  </span>
                                </v-btn></v-flex>
                        </v-layout>
                    </v-flex>
                </v-layout>
            </v-card>
        </v-dialog>


    </div>
</template>
<script>
import axios from "axios";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import { VueEditor } from 'vue2-editor';

export default {
    components: {
        Cropper,
        VueEditor
    },
    data() {
        return {
            ServerError: false,
            bloglist: [],
            formErrors: [],
            showSnackBar: false,
            deletedialog: false,
            itemToDelete: null,
            dialogdes: false,
            dialogdes2: false,
            timeout: 5000,
            msg: "",
            appLoading: false,
            page: 1,
            currentPage: 1,
            pages: 0,
            limit: 10,
            fullDescription: '',
            fullDescription2: '',
            addBlog: false,
            valid: false,
            title: "",
            subDescription: "",
            content: "",
            imageSelected: false,
            imageURL: "",
            imageFile: null,
            editBlogDialog: false,
            editedBlog: {
                _id: null,
                title: "",
                shortDiscription: "",
                content: "",
                images: [],
            },
            editImageSelected: false, // To check if a new image is selected
            editImageURL: "",
        };
    },

    //   computed: {
    //   filteredHeaders() {
    //     if (this.userRole === 'subadmin') {
    //       return this.headers.filter(header => header.value !== '_id');
    //     }
    //     return this.headers;
    //   }
    // },
    mounted() {

        this.getData();
    },
    methods: {
        redirectToViewPage(item) {
      this.$router.push(
        `/Blogs/viewWildByte?id=${item._id}`
      );
    },
        onFileChange(file) {
            if (file) {
                this.imageURL = URL.createObjectURL(file);
                this.imageSelected = true;
            } else {
                console.error("No file selected.");
            }
        },
        validateForm() {
            this.formErrors = [];

            if (!this.title) {
                this.formErrors.push("Title is required.");
            }
            if (!this.subDescription) {
                this.formErrors.push("Sub description is required.");
            }
            if (!this.content) {
                this.formErrors.push("Content is required.");
            }
            if (!this.imageSelected) {
                this.formErrors.push("Cover Image is required.");
            }

            if (this.formErrors.length > 0) {
                this.msg = this.formErrors.join(" ");
                this.showSnackBar = true;
                return false;
            }
            return true;
        },
        async addBlogs() {
            // Check if form is valid
            if (!this.validateForm()) {
                return;
            }

            // Ensure cropper is ready and get cropped image as a blob
            if (this.$refs.cropper) {
                const canvas = this.$refs.cropper.getResult().canvas;
                if (canvas) {
                    canvas.toBlob(async (blob) => {
                        if (blob) {
                            let formData = new FormData();
                            formData.append("title", this.title);
                            formData.append("subdiscription", this.subDescription);
                            formData.append("content", this.content);
                            formData.append("images", blob); // Add cropped image as blob

                            try {
                                const response = await axios.post("/employee/blog/add", formData, {
                                    headers: { "x-auth-token": localStorage.getItem("token") },
                                });
                                if (response.data.status) {
                                    this.msg = response.data.msg;
                                    this.showSnackBar = true;
                                    this.addBlog = false;

                                    // Clear form fields after successful addition
                                    this.title = "";
                                    this.subDescription = "";
                                    this.content = "";
                                    this.imageURL = "";
                                    this.imageSelected = false;

                                    this.getData();
                                } else {
                                    this.msg = response.data.msg;
                                    this.showSnackBar = true;
                                }
                            } catch (error) {
                                console.error("Error adding blog:", error);
                            }
                        } else {
                            console.error("No cropped image available.");
                        }
                    });
                }
            }
        },




        editItem(item) {
            this.editedBlog = { ...item }; // Copy existing item data
            this.editImageSelected = false; // Reset image selection status
            this.editBlogDialog = true;
        },


        // Handle image change in edit form
        onEditFileChange(file) {
            if (file) {
                this.editImageURL = URL.createObjectURL(file);
                this.editImageSelected = true;
            }
        },

        // Update the blog
        onEditFileChange2(file) {
            if (file) {
                this.editImageURL = URL.createObjectURL(file);
                this.editImageSelected = true; // Show cropper when a new image is selected
            } else {
                console.error("No file selected.");
            }
        },

        async updateBlog() {
            // Basic validation checks
            if (!this.editedBlog.title || !this.editedBlog.shortDiscription || !this.editedBlog.content) {
                this.msg = "All fields are required.";
                this.showSnackBar = true;
                return;
            }

            let formData = new FormData();
            formData.append("title", this.editedBlog.title);
            formData.append("id", this.editedBlog._id);
            formData.append("shortDiscription", this.editedBlog.shortDiscription);
            formData.append("content", this.editedBlog.content);

            // Check if a new image is selected and crop it
            if (this.editImageSelected && this.$refs.editCropper) {
                const canvas = this.$refs.editCropper.getResult().canvas;
                canvas.toBlob(async (blob) => {
                    formData.append("images", blob); // Add cropped image as blob

                    try {
                        const response = await axios.post(
                            `/employee/blog/edit`,
                            formData,
                            {
                                headers: { "x-auth-token": localStorage.getItem("token") },
                            }
                        );
                        if (response.data.status) {
                            this.msg = response.data.msg;
                            this.showSnackBar = true;
                            this.getData();
                            this.editBlogDialog = false;
                            this.clearEditForm(); // Clear form on success
                        } else {
                            this.msg = response.data.msg;
                            this.showSnackBar = true;
                        }
                    } catch (error) {
                        console.error("Error updating blog:", error);
                        this.msg = "An error occurred while updating the blog.";
                        this.showSnackBar = true;
                    }
                });
            } else {
                try {
                    const response = await axios.post(
                        `/employee/blog/edit`,
                        formData,
                        {
                            headers: { "x-auth-token": localStorage.getItem("token") },
                        }
                    );
                    if (response.data.status) {
                        this.msg = response.data.msg;
                        this.showSnackBar = true;
                        this.getData();
                        this.editBlogDialog = false;
                    } else {
                        this.msg = response.data.msg;
                        this.showSnackBar = true;
                    }
                } catch (error) {
                    console.error("Error updating blog:", error);
                    this.msg = "An error occurred while updating the blog.";
                    this.showSnackBar = true;
                }
            }
        },
        getData() {
            this.appLoading = true;
            axios({
                url: "/employee/blog/list",
                method: "GET",
                headers: {
                    "x-auth-token": localStorage.getItem("token"),
                },
                //   params: {
                //     limit: this.limit,
                //     page: this.currentPage,
                //     username: this.search,
                //   },
            })
                .then((response) => {
                    this.appLoading = false;
                    this.bloglist = response.data.data;
                    //this.pages = Math.ceil(response.data.totalLength / this.limit);
                })
                .catch((err) => {
                    this.appLoading = false;
                    (this.ServerError = true), console.log(err);
                });
        },
        showFullDescription(shortDiscription) {
            this.fullDescription = shortDiscription;
            this.dialogdes = true;
        },
        showFullDescription2(content) {
            this.fullDescription2 = content;
            this.dialogdes2 = true;
        },
        //      edit() {
        //     var data = {};
        //     data["catName"] = this.editingitem.catName;
        //     data["priority"] = this.editingitem.priority;
        //     data["desc"] = this.editingitem.desc;
        //     data["id"] = this.editingitem._id;
        //     axios({
        //       url: "/v1/admin/edit/project/category",
        //       method: "PUT",
        //       data: data,
        //       headers: {
        //         token: localStorage.getItem("token"),
        //       },
        //     })
        //       .then((response) => {
        //         this.appLoading = false;
        //         if (response.data.status) {
        //         //   this.msg = response.data.msg;
        //         //   this.showSnackBar = true;
        //         //   this.editdialog = false;
        //         this.msg = response.data.msg;
        //         this.showSnackBar = true;
        //         this.editdialog = false;
        //         this.getData();
        //         } else {
        //             this.msg = response.data.msg;
        //             this.showSnackBar = true;
        //         }
        //       })
        //       .catch((err) => {
        //         this.appLoading = false;
        //         this.ServerError = true;
        //         console.log(err);
        //       });
        //   },

        openDeleteDialog(item) {
            this.itemToDelete = item;
            this.deletedialog = true;
        },
        confirmDelete() {
            if (this.itemToDelete) {
                this.deleteItem(this.itemToDelete);
            }
            this.deletedialog = false;
        },
        deleteItem(r) {
            var data = {};
            data["id"] = r._id;
            axios({
                url: "/employee/blog/delete",
                method: "post",
                data: data,
                headers: {
                    "x-auth-token": localStorage.getItem("token"),
                },
            })
                .then((response) => {
                    this.delete = false;
                    this.appLoading = false;
                    if (response.data.status) {
                        this.msg = response.data.msg;
                        this.showSnackBar = true;
                        this.getData();
                    } else {
                        this.msg = response.data.msg;
                        this.showSnackBar = true;
                    }
                })
                .catch((err) => {
                    this.appLoading = false;
                    this.ServerError = true;
                    console.log(err);
                });
        },
    },

};
</script>



<style scoped>
.dialog-card {
    font-family: interbold;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.dialog-icon {
    animation: pulse 1s infinite alternate;
}

.dialog-button {
    min-width: 120px;
}

@keyframes pulse {
    from {
        transform: scale(1);
        opacity: 0.7;
    }

    to {
        transform: scale(1.1);
        opacity: 1;
    }
}


.video-card {
    margin: 10px;
    cursor: pointer;
}

.video-title {
    font-size: 14px;
    font-family: kumbhBold;
    text-transform: uppercase;
}

.video-duration {
    color: grey;
    font-size: 12px;
}

.card-header {
    background-color: #f5f5f5;
    padding: 16px;
    font-family: kumbhMedium;
    border-bottom: 1px solid #e0e0e0;
}

.centered-text {
    text-align: center;
    padding-top: 4rem;
    padding-bottom: 4rem;
    background-color: white;
    opacity: 0.8;
    border-radius: 4px;
}
</style>